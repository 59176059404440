<script setup lang="ts">
import definitions from '@respell/steps';
import AppVariableTag from '~/components/app/AppVariableTag.vue';

const { selectedStep } = useSelectedStep();

const variables = computed(
  () => definitions[selectedStep.value?.data.key]?.outputs || {},
);
</script>
<template>
  <div class="flex flex-col justify-start items-start w-full px-7 gap-4">
    <p class="subtitle">Outputs</p>
    <UFormGroup v-for="(variable, key) in variables" :key="key" class="w-full">
      <template #label>
        <AppVariableTag size="sm" :variable="variable" />
      </template>
      <UInput
        v-if="selectedStep"
        v-model="selectedStep.data.context[variable.key]"
        class="pt-2 w-full"
        placeholder="Describe the purpose of this variable within the spell"
        size="lg"
      />
    </UFormGroup>
  </div>
</template>
