<script setup lang="ts">
import definitions from '@respell/steps';
import type { NodeProps } from '@vue-flow/core';
import { Handle, Position, useHandleConnections } from '@vue-flow/core';
import AddStepButton from './AddStepButton.vue';
import NodeCard from './NodeCard.vue';

const props = defineProps<NodeProps>();
const stepType = definitions[props.data.key];
const canvasStore = useCanvasStore();

const activeSources = useHandleConnections({
  nodeId: props.id,
  type: 'source',
});

const activeTargets = useHandleConnections({
  nodeId: props.id,
  type: 'target',
});

watch([activeTargets, activeSources], () => {
  canvasStore.validateStep(props.data.slug, props.data.key, props.data.options);
});
</script>

<template>
  <Handle
    type="target"
    :position="Position.Left"
    class="handle"
    :class="{ 'is-connected': activeTargets.length }"
  />
  <Handle
    type="source"
    :position="Position.Right"
    class="handle"
    :class="{ 'is-connected': activeSources.length }"
  />

  <div
    v-if="!activeSources.length"
    class="absolute -top-[20px] -right-1/4 h-24 mr-s flex flex-row max-w-16 items-center"
    @click.stop
  >
    <div class="w-12 h-[1px] shrink bg-gray-300 rounded-full" />
    <AddStepButton :source="props.id" type="step" />
  </div>

  <NodeCard v-bind="props" node-type="step" :step-type="stepType" />
</template>
<style lang="scss" scoped>
.handle {
  width: 16px;
  height: 16px;
}
</style>
