<script setup lang="ts">
import { typeMap } from '@respell/utils';
import {
  moveArrayElement,
  useSortable,
} from '@vueuse/integrations/useSortable';
import { computed, nextTick, ref, watch } from 'vue';

const modelValue = defineModel<
  | { value: string | undefined; type?: string; name?: string; key?: string }[]
  | Record<
      string,
      { value: string | undefined; type?: string; name?: string; key?: string }
    >
>();

const props = defineProps<{
  optionType: string;
  readOnly?: boolean;
  handleDelete: (key: string | number) => void;
  handleAdd?: () => void;
  type?: string;
}>();

const canvasStore = useCanvasStore();
const el = ref<HTMLElement | null>(null);

const isArray = computed(() => Array.isArray(modelValue.value));
const options = computed(() => {
  return isArray.value
    ? modelValue.value
    : Object.values(modelValue.value || {});
});

const uniqueKeysMap = ref(new Map());

watch(
  options,
  (newOptions, oldOptions) => {
    // Add new unique keys for added items
    newOptions.forEach((item, index) => {
      if (!uniqueKeysMap.value.has(item)) {
        uniqueKeysMap.value.set(item, Math.random().toString(36).substr(2, 9));
      }
    });

    // Remove unique keys for removed items
    oldOptions.forEach((item) => {
      if (!newOptions.includes(item)) {
        uniqueKeysMap.value.delete(item);
      }
    });
  },
  { deep: true },
);

useSortable(el, options, {
  handle: '.dragHandle',
  animation: 150,
  onUpdate: (e) => {
    if (isArray.value) {
      moveArrayElement(modelValue.value, e.oldIndex, e.newIndex);
      nextTick();
    }
  },
});

const handleEdit = (option) => {
  if (props.readOnly) {
    canvasStore.editVariable(option);
  }
};
</script>
<template>
  <div class="flex flex-col items-center gap-3 pt-s">
    <div ref="el" class="contents">
      <div
        v-for="(item, index) in options"
        :key="uniqueKeysMap.get(item)"
        class="flex flex-row w-full justify-between items-center gap-2"
      >
        <UIcon
          v-if="isArray"
          name="i-ph-dots-six-vertical-bold"
          class="text-gray-400 dragHandle cursor-move text-4xl hover:text-gray-500 transition-colors"
        />
        <UInput
          v-model="item.name"
          placeholder="Create an option"
          :type="
            readOnly
              ? 'text'
              : type?.includes('number')
                ? 'number'
                : type?.includes('file')
                  ? 'file'
                  : 'text'
          "
          :ui="readOnly ? { base: 'cursor-pointer' } : {}"
          color="white"
          class="w-full"
          size="lg"
          :trailing="false"
          :readonly="readOnly"
          @click="() => handleEdit(item)"
        >
          <template #leading>
            <UIcon
              class="text-2xl text-gray-400"
              :name="
                item.listDepth > 0
                  ? 'i-ph-brackets-square'
                  : typeMap[item.type ?? type ?? 'text/plain'].icon
              "
            />
          </template>
        </UInput>
        <UIcon
          name="i-ph-trash-simple"
          class="text-gray-400 cursor-pointer text-2xl hover:text-gray-500 transition-colors"
          @click="() => handleDelete(item.key ?? index)"
        />
      </div>
    </div>
    <UButton
      v-if="handleAdd"
      icon="i-ph-plus-bold"
      size="md"
      color="gray"
      variant="ghost"
      class="border border-gray-200 border-dashed text-gray-400 m"
      :label="`Add ${optionType}`"
      block
      :ui="{
        size: { lg: 'text-lg' },
        icon: { size: { lg: 'h-5 w-5' } },
      }"
      @click="handleAdd"
    />
  </div>
</template>
